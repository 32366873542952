const apiConfig = {
    API_BASE_URL: 'https://hkex-crm.com',
    MUSIC_BASE_URL: 'https://minio.hkex-crm.com',
}

// const apiConfig = {
//     API_BASE_URL: 'https://dev.hkex-crm.com',
//     MUSIC_BASE_URL: 'https://dev.hkex-crm.com/files',
// }






const titleConfig = {
    USERS: "Пользователи | HKEX-CRM",
    STATUSES: "Статусы | HKEX-CRM",
    RECORDINGS: "Записи | HKEX-CRM",
    LEADS: "Лиды | HKEX-CRM",
    LOGIN: "Логин | HKEX-CRM",
    EXPENSES: "Расходы | HKEX-CRM",
    DEPOSITS: "Депозиты | HKEX-CRM",
    DASHBOARD: "Статистика | HKEX-CRM",
    COMMENTS: "Комментарии | HKEX-CRM",
    CALENDAR: "Календарь | HKEX-CRM",
    BASES: "Базы | HKEX-CRM",

}

export {apiConfig, titleConfig} 